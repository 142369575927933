@import '@/assets/scss/mixins';
.root {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text {
    background-color: transparent;
    padding: 120px 0;
    width: 50%;
    min-width: 50%;
    @include screen('medium') {
        padding-bottom: 165px;
        padding-top: 64px;
    }
    @include screen('medium') {
        width: 100%;
        min-width: 100%;
    }
    .pretitle {
        transition: color 350ms ease-in-out;
        font-weight: 700;
        color: var(--orangeLight);
        &.isActive {
            color: black;
        }
    }
    .titleWrapper {
        max-width: 600px;
        display: flex;
        justify-content: space-between;
        
        @include screen('medium') {
            max-width: 100%;
            gap: 0;
        }

        .title {
            transition: color 350ms ease-in-out;

            color: var(--core90);
            &.isActive {
                color: black;
            }
        }
        .icon {
            transition: color 350ms ease-in-out;

            &.isActive {
                color: black;
            }
        }
    }
}

.wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    transition: all 300ms ease-in-out;
    padding-inline: 20px;
    @include screen('medium') {
        gap: 0;
        flex-direction: column;
    }
}

.isActive {
    .pretitle {
        color: black;
    }
    .titleWrapper {
        .title {
            color: black;
        }
        .icon {
            color: black;
        }
    }
}
.desktop {
    display: block;

    @include screen('medium') {
        display: none;
    }
}
.mobile {
    display: none;
    margin-bottom: 180px;
    @include screen('medium') {
        display: block;
    }
    .image {
        max-width: 100%;
        position: absolute;
        bottom: -165px;
    }
}
